.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: .5rem;
  margin-bottom: 0;
  //font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}

.product {

  &--order {
    display: inline-block;
    box-sizing: border-box;
    position: relative;

    input[type='radio'] {
      background-color: white;
      border: 3px solid black;
      width: 1rem;
      height: 1rem;
      padding: 0;
      cursor: pointer;
      //margin-left: 1rem;
      border-radius: 50%;
      //display: inline;
      //position: absolute;

      &:focus {
        box-shadow: none;
      }

      &:checked {
        background-color: black;
      }

      &:disabled {

        border-color: rgba(0, 0, 0, .05);
        cursor: auto;

        & + label {
          color: rgba(0, 0, 0, .05);
          cursor: auto;
        }
      }
    }

    label {
      cursor: pointer;
    }

    button {
      margin-top: 1rem;
    }

  }

  &--image {
    width: 100%;

    @include breakpoint(m) {
      width: 50%;
      float: left;
    }

    img {
      width: 100%;
    }
  }

  &--info {
    margin: 2rem;
    text-align: center;

    @include breakpoint(m) {
      width: calc(50% - 4rem);
      float: left;
    }

    &-description {
      margin-bottom: 1rem;
    }

    &-price {
      margin-bottom: .5rem;
      text-transform: uppercase;
    }
  }
}
