/**
 * Basic grid styles
 *
 * Use col- classes for individual elements
 * Use grid- classes for repeating elements with same width
 * Use gallery- for flickity galleries
 *
**/

@import "mixins";

$grid-gutter: 1rem;
$grid-count: 12;
//$debug-border: 1px solid pink;

.module {
  margin: 0 auto;
  width: 95%;
  max-width: 40em;
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
  //border: 1px solid lightgrey;

  &.full {
    width: 100%;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.row {
  margin: 0 -1rem;
  //border: 1px solid pink;


  &:after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0;
  }
}

[class*='col-'] {
  float: left;
  //border: 1px solid pink;
}

[class*='grid-'] {
  padding: 0;
  margin: 0 -$grid-gutter;

  &:after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0;
  }

  & > li {
    float: left;
    //border: $debug-border;
    margin: $grid-gutter;
  }
}

$breakpoints: 's', 'm', 'l', 'xl';

@for $i from 1 through length($breakpoints) {

  $breakpoint: nth($breakpoints, $i);

  @include twixt($breakpoint) {
    .#{$breakpoint}-hide {
      display: none;
    }
  }

  @include breakpoint($breakpoint) {
    @for $i from 1 through $grid-count {
      .col-#{$breakpoint}-#{$i} {
        width: calc(100% / 12 * #{$i});
        padding: 0 1rem;
      }

      .col-#{$breakpoint}-offset-#{$i} {
        margin-left: calc(100% / 12 * #{$i});
      }

      .grid-#{$breakpoint}-#{$i} {
        & > li {
          width: calc(100% / #{$i} - #{$grid-gutter} * 2);

          &:nth-of-type(1n) {
            clear: none;
          }

          &:nth-of-type(#{$i}n + 1) {
            clear: both;
          }
        }

        .gallery-cell {
          width: calc(100% / #{$i});
          padding: 1rem;
        }
      }
    }
  }
}