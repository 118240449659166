
.cart {
  width: 90%;
  margin: 0 auto;

  @include breakpoint(m) {
    width: 66%;
  }

  @include breakpoint(l) {
    width: 50%;
  }

  &--overview {
    border: 3px solid black;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    box-sizing: content-box;
    line-height: 2rem;

    @include breakpoint(m) {
      margin-left: -2rem;
      margin-right: -2rem;
    }

    ul {
      display: table;
      width: 100%;

      & > li {
        display: table-row;
      }
    }
  }

  &--item {

    & > span {
      display: table-cell;
    }

    &-quantity {
      width: 6rem;
      padding-right: 0.5rem;
      margin-bottom: 3px;
    }

    &-name {
      padding-right: 2rem;
    }

    &-option {
      padding-right: 1rem;
    }

    &-remove {


    }

    &-price-single,
    &-price-total {
      padding-left: 1rem;
      text-align: right;
    }

    &-price-single {

      @include breakpoint(s) {
        display: none !important;
      }
    }
  }

  &--footer {
    border-top: 3px solid black;
    margin-top: 1rem;
    padding-top: .5rem;

    &-shipping {

      span {
        float: right;
      }
    }

    &-total {

      span {
        float: right;
      }
    }
  }

  &--hint {
    color: grey;
    display: none;

    @include breakpoint(m) {
      display: block;
      margin: 1rem 0;
    }
  }

  &--button {

    &-proceed {
      padding: 1rem;
      float: left;
    }

    &-submit {
      float: right;
    }
  }

  &--no-items {
    text-align: center;
    margin-top: 3rem;
  }
}