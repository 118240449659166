@import "mixins";

@import "reset";

@import "typography";

@import "grid";

@import "shop.layout";

@import "shop.cart";
@import "shop.product";
@import "shop.confirmation";
@import "shop.success";



/* Custom Normalize */

button,
select,
input {
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  border-radius: 0;
  line-height: inherit;
}

input,
select {
  border-bottom: 2px solid black;
  padding: .5rem 0;
  width: 100%;
  background-color: transparent;

  &:focus {
    border-bottom-color: pink;
  }
}

label {
  display: block;
  font-size: small;
}

select {

}

.badge {
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: block;
  text-align: center;

  &-success {
    background-color: green;
  }
}

.form-group {
  margin-bottom: 1rem;
  position: relative;

  .badge {
    position: absolute;
    bottom: .5rem;
    right: 0;
  }

  &.has-error {
    color: red;

    input {
      border-bottom-color: red;
      color: black;
    }
  }
}

button {
  cursor: pointer;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

* {

}

*:focus {
  outline: none;
}

*:hover {
  box-shadow: none;
}

button,
a {
  transition: background-color .25s ease;
}

::-moz-selection {
  background-color: pink;
  color: white;
}

::selection {
  background-color: pink;
  color: white;
}




/* Design */

@import "elements";

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}


.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.input-number {
  background-color: black;
  position: relative;
  padding: 0 2rem;

  form {
    display: inline-block;
    position: absolute;
    top: 0;
    width: 2rem;
    height: 2rem;

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
    }
  }

  div {
    color: white;
    display: block;
    width: 100%;
    text-align: center;
  }

  button {
    color: white;
    display: inline-block;
    width: 100%;
    line-height: 2rem;
    text-align: center;
    border-radius: 50%;

    &:focus {
      box-shadow: none;
    }


  }
}


#image-02 {
  position: relative;
  left: -6rem;
  margin-top: 2rem;
}

#image-03 {
  width: 80%;
  margin: 1rem 5% 2rem 15%;
}

.fragen {
  width: 90%;
  margin: 0 auto;

  p {
    margin-bottom: 1rem;
  }

  @include breakpoint(m) {
    width: 50%;
  }
}

.loader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility .0s .3s, opacity 0.25s ease;
}

.is-added {
  &:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include animation(addition .5s ease-out);
    box-sizing: border-box;
    border-radius: 50%;
    background-color: pink;
  }

  &:focus {
    box-shadow: none;
  }
}

.is-loader {
  .loader {
    visibility: visible;
    opacity: 1;
    transition: visibility .0s, opacity 0.25s ease;
  }

  .navigation--link-base {
    -webkit-animation: rotate-infinite 1s linear infinite;
    -moz-animation: rotate-infinite 1s linear infinite;
    animation: rotate-infinite 1s linear infinite;
  }
}

.is-alert {
  .alert {
    //display: block;
  }

  header {
    //@include translate(0, 0);
  }
}

@-webkit-keyframes rotate-infinite {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-infinite {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate-infinite {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes addition {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

@-moz-keyframes addition {
  0% {
    -moz-transform: scale(1);
    opacity: 1;
  }

  100% {
    -moz-transform: scale(2);
    opacity: 0;
  }
}

@keyframes addition {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.debug {
  background-color: #111;
  color: white;
  font-size: small;
  padding: 3rem 1rem;
  margin: 1rem;
  position: relative;

  &:before {
    content: 'DEBUG INFORMATION';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: pink;
    color: black;
    width: 100%;
    padding: .5rem 1rem;
    box-sizing: border-box;
  }
}

footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@import "fonts";