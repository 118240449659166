[class*='button-'] {
  display: inline-block;
  padding: 1rem;
  text-decoration: none;
}

.button {
  &-black {
    background-color: black;
    color: white;
  }

  &-pink {
    background-color: pink;
    color: white;

    &:hover {
      background-color: black;
    }
  }

  &-close {
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1rem;
    line-height: 1;
    cursor: pointer;
    padding: 0;
  }
}

.label {
  padding: .5rem 1rem;
  display: inline-block;
  margin: 0 auto;
  position: relative;

  &:after {
    content: '';
    display: block;
    border: 1rem solid transparent;
    border-left-color: pink;
    position: absolute;
    bottom: -.9rem; //save complete overlap
    left: 0;
  }

  &-warning {
    background-color: pink;
  }
}

p {
  margin-bottom: 1rem;
}

.box-border {
  border: 3px solid black;
  padding: 1rem 2rem;
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: 1rem;
}

ol {
  list-style-type: decimal;
  margin-bottom: 1rem;
}

ul {
  margin-bottom: 1rem;
}