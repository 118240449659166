@font-face {
  font-family: 'Moderat';
  src: url('../fonts/moderat-regular-webfont.eot');
  src: url('../fonts/moderat-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/moderat-regular-webfont.woff2') format('woff2'),
  url('../fonts/moderat-regular-webfont.woff') format('woff'),
  url('../fonts/moderat-regular-webfont.ttf') format('truetype'),
  url('../fonts/moderat-regular-webfont.svg#moderatregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Moderat';
  src: url('../fonts/moderat-bold-webfont.eot');
  src: url('../fonts/moderat-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/moderat-bold-webfont.woff2') format('woff2'),
  url('../fonts/moderat-bold-webfont.woff') format('woff'),
  url('../fonts/moderat-bold-webfont.ttf') format('truetype'),
  url('../fonts/moderat-bold-webfont.svg#moderatbold') format('svg');
  font-weight: bold;
  font-style: normal;
}