/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
form, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 *
 * Defining basic typography rules
 *
**/
html {
  font-size: 70%;
}

@media screen and (min-width: 481px) {
  html {
    font-size: 90%;
  }
}

@media screen and (min-width: 769px) {
  html {
    font-size: 100%;
  }
}

@media screen and (min-width: 961px) {
  html {
    font-size: 110%;
  }
}

body {
  font-family: 'Moderat', 'Arial', sans-serif;
  line-height: 1.5;
  letter-spacing: -0.015em;
}

h1 {
  font-size: 4rem;
}

h2 {
  border-bottom: 3px solid black;
  display: inline-block;
  margin-bottom: 1rem;
}

h4 {
  display: inline;
  background-color: pink;
}

a {
  color: pink;
}

strong {
  font-weight: bold;
}

small {
  font-size: 0.75em;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/**
 * Basic grid styles
 *
 * Use col- classes for individual elements
 * Use grid- classes for repeating elements with same width
 * Use gallery- for flickity galleries
 *
**/
.module {
  margin: 0 auto;
  width: 95%;
  max-width: 40em;
  padding-left: 1rem;
  padding-right: 1rem;
}

.module.full {
  width: 100%;
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}

.row {
  margin: 0 -1rem;
}

.row:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0;
}

[class*='col-'] {
  float: left;
}

[class*='grid-'] {
  padding: 0;
  margin: -1rem;
}

[class*='grid-']:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0;
}

[class*='grid-'] > li {
  float: left;
  margin: 1rem;
}

@media screen and (max-width: 37.5em) {
  .s-hide {
    display: none;
  }
}

.col-s-1 {
  width: calc(100% / 12 * 1);
  padding: 0 1rem;
}

.col-s-offset-1 {
  margin-left: calc(100% / 12 * 1);
}

.grid-s-1 > li {
  width: calc(100% / 1 - 1rem * 2);
}

.grid-s-1 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-1 > li:nth-of-type(1n + 1) {
  clear: both;
}

.grid-s-1 .gallery-cell {
  width: calc(100% / 1);
  padding: 1rem;
}

.col-s-2 {
  width: calc(100% / 12 * 2);
  padding: 0 1rem;
}

.col-s-offset-2 {
  margin-left: calc(100% / 12 * 2);
}

.grid-s-2 > li {
  width: calc(100% / 2 - 1rem * 2);
}

.grid-s-2 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-2 > li:nth-of-type(2n + 1) {
  clear: both;
}

.grid-s-2 .gallery-cell {
  width: calc(100% / 2);
  padding: 1rem;
}

.col-s-3 {
  width: calc(100% / 12 * 3);
  padding: 0 1rem;
}

.col-s-offset-3 {
  margin-left: calc(100% / 12 * 3);
}

.grid-s-3 > li {
  width: calc(100% / 3 - 1rem * 2);
}

.grid-s-3 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-3 > li:nth-of-type(3n + 1) {
  clear: both;
}

.grid-s-3 .gallery-cell {
  width: calc(100% / 3);
  padding: 1rem;
}

.col-s-4 {
  width: calc(100% / 12 * 4);
  padding: 0 1rem;
}

.col-s-offset-4 {
  margin-left: calc(100% / 12 * 4);
}

.grid-s-4 > li {
  width: calc(100% / 4 - 1rem * 2);
}

.grid-s-4 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-4 > li:nth-of-type(4n + 1) {
  clear: both;
}

.grid-s-4 .gallery-cell {
  width: calc(100% / 4);
  padding: 1rem;
}

.col-s-5 {
  width: calc(100% / 12 * 5);
  padding: 0 1rem;
}

.col-s-offset-5 {
  margin-left: calc(100% / 12 * 5);
}

.grid-s-5 > li {
  width: calc(100% / 5 - 1rem * 2);
}

.grid-s-5 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-5 > li:nth-of-type(5n + 1) {
  clear: both;
}

.grid-s-5 .gallery-cell {
  width: calc(100% / 5);
  padding: 1rem;
}

.col-s-6 {
  width: calc(100% / 12 * 6);
  padding: 0 1rem;
}

.col-s-offset-6 {
  margin-left: calc(100% / 12 * 6);
}

.grid-s-6 > li {
  width: calc(100% / 6 - 1rem * 2);
}

.grid-s-6 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-6 > li:nth-of-type(6n + 1) {
  clear: both;
}

.grid-s-6 .gallery-cell {
  width: calc(100% / 6);
  padding: 1rem;
}

.col-s-7 {
  width: calc(100% / 12 * 7);
  padding: 0 1rem;
}

.col-s-offset-7 {
  margin-left: calc(100% / 12 * 7);
}

.grid-s-7 > li {
  width: calc(100% / 7 - 1rem * 2);
}

.grid-s-7 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-7 > li:nth-of-type(7n + 1) {
  clear: both;
}

.grid-s-7 .gallery-cell {
  width: calc(100% / 7);
  padding: 1rem;
}

.col-s-8 {
  width: calc(100% / 12 * 8);
  padding: 0 1rem;
}

.col-s-offset-8 {
  margin-left: calc(100% / 12 * 8);
}

.grid-s-8 > li {
  width: calc(100% / 8 - 1rem * 2);
}

.grid-s-8 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-8 > li:nth-of-type(8n + 1) {
  clear: both;
}

.grid-s-8 .gallery-cell {
  width: calc(100% / 8);
  padding: 1rem;
}

.col-s-9 {
  width: calc(100% / 12 * 9);
  padding: 0 1rem;
}

.col-s-offset-9 {
  margin-left: calc(100% / 12 * 9);
}

.grid-s-9 > li {
  width: calc(100% / 9 - 1rem * 2);
}

.grid-s-9 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-9 > li:nth-of-type(9n + 1) {
  clear: both;
}

.grid-s-9 .gallery-cell {
  width: calc(100% / 9);
  padding: 1rem;
}

.col-s-10 {
  width: calc(100% / 12 * 10);
  padding: 0 1rem;
}

.col-s-offset-10 {
  margin-left: calc(100% / 12 * 10);
}

.grid-s-10 > li {
  width: calc(100% / 10 - 1rem * 2);
}

.grid-s-10 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-10 > li:nth-of-type(10n + 1) {
  clear: both;
}

.grid-s-10 .gallery-cell {
  width: calc(100% / 10);
  padding: 1rem;
}

.col-s-11 {
  width: calc(100% / 12 * 11);
  padding: 0 1rem;
}

.col-s-offset-11 {
  margin-left: calc(100% / 12 * 11);
}

.grid-s-11 > li {
  width: calc(100% / 11 - 1rem * 2);
}

.grid-s-11 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-11 > li:nth-of-type(11n + 1) {
  clear: both;
}

.grid-s-11 .gallery-cell {
  width: calc(100% / 11);
  padding: 1rem;
}

.col-s-12 {
  width: calc(100% / 12 * 12);
  padding: 0 1rem;
}

.col-s-offset-12 {
  margin-left: calc(100% / 12 * 12);
}

.grid-s-12 > li {
  width: calc(100% / 12 - 1rem * 2);
}

.grid-s-12 > li:nth-of-type(1n) {
  clear: none;
}

.grid-s-12 > li:nth-of-type(12n + 1) {
  clear: both;
}

.grid-s-12 .gallery-cell {
  width: calc(100% / 12);
  padding: 1rem;
}

@media screen and (min-width: 37.5em) and (max-width: 64em) {
  .m-hide {
    display: none;
  }
}

@media screen and (min-width: 37.5em) {
  .col-m-1 {
    width: calc(100% / 12 * 1);
    padding: 0 1rem;
  }
  .col-m-offset-1 {
    margin-left: calc(100% / 12 * 1);
  }
  .grid-m-1 > li {
    width: calc(100% / 1 - 1rem * 2);
  }
  .grid-m-1 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-1 > li:nth-of-type(1n + 1) {
    clear: both;
  }
  .grid-m-1 .gallery-cell {
    width: calc(100% / 1);
    padding: 1rem;
  }
  .col-m-2 {
    width: calc(100% / 12 * 2);
    padding: 0 1rem;
  }
  .col-m-offset-2 {
    margin-left: calc(100% / 12 * 2);
  }
  .grid-m-2 > li {
    width: calc(100% / 2 - 1rem * 2);
  }
  .grid-m-2 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-2 > li:nth-of-type(2n + 1) {
    clear: both;
  }
  .grid-m-2 .gallery-cell {
    width: calc(100% / 2);
    padding: 1rem;
  }
  .col-m-3 {
    width: calc(100% / 12 * 3);
    padding: 0 1rem;
  }
  .col-m-offset-3 {
    margin-left: calc(100% / 12 * 3);
  }
  .grid-m-3 > li {
    width: calc(100% / 3 - 1rem * 2);
  }
  .grid-m-3 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-3 > li:nth-of-type(3n + 1) {
    clear: both;
  }
  .grid-m-3 .gallery-cell {
    width: calc(100% / 3);
    padding: 1rem;
  }
  .col-m-4 {
    width: calc(100% / 12 * 4);
    padding: 0 1rem;
  }
  .col-m-offset-4 {
    margin-left: calc(100% / 12 * 4);
  }
  .grid-m-4 > li {
    width: calc(100% / 4 - 1rem * 2);
  }
  .grid-m-4 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-4 > li:nth-of-type(4n + 1) {
    clear: both;
  }
  .grid-m-4 .gallery-cell {
    width: calc(100% / 4);
    padding: 1rem;
  }
  .col-m-5 {
    width: calc(100% / 12 * 5);
    padding: 0 1rem;
  }
  .col-m-offset-5 {
    margin-left: calc(100% / 12 * 5);
  }
  .grid-m-5 > li {
    width: calc(100% / 5 - 1rem * 2);
  }
  .grid-m-5 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-5 > li:nth-of-type(5n + 1) {
    clear: both;
  }
  .grid-m-5 .gallery-cell {
    width: calc(100% / 5);
    padding: 1rem;
  }
  .col-m-6 {
    width: calc(100% / 12 * 6);
    padding: 0 1rem;
  }
  .col-m-offset-6 {
    margin-left: calc(100% / 12 * 6);
  }
  .grid-m-6 > li {
    width: calc(100% / 6 - 1rem * 2);
  }
  .grid-m-6 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-6 > li:nth-of-type(6n + 1) {
    clear: both;
  }
  .grid-m-6 .gallery-cell {
    width: calc(100% / 6);
    padding: 1rem;
  }
  .col-m-7 {
    width: calc(100% / 12 * 7);
    padding: 0 1rem;
  }
  .col-m-offset-7 {
    margin-left: calc(100% / 12 * 7);
  }
  .grid-m-7 > li {
    width: calc(100% / 7 - 1rem * 2);
  }
  .grid-m-7 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-7 > li:nth-of-type(7n + 1) {
    clear: both;
  }
  .grid-m-7 .gallery-cell {
    width: calc(100% / 7);
    padding: 1rem;
  }
  .col-m-8 {
    width: calc(100% / 12 * 8);
    padding: 0 1rem;
  }
  .col-m-offset-8 {
    margin-left: calc(100% / 12 * 8);
  }
  .grid-m-8 > li {
    width: calc(100% / 8 - 1rem * 2);
  }
  .grid-m-8 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-8 > li:nth-of-type(8n + 1) {
    clear: both;
  }
  .grid-m-8 .gallery-cell {
    width: calc(100% / 8);
    padding: 1rem;
  }
  .col-m-9 {
    width: calc(100% / 12 * 9);
    padding: 0 1rem;
  }
  .col-m-offset-9 {
    margin-left: calc(100% / 12 * 9);
  }
  .grid-m-9 > li {
    width: calc(100% / 9 - 1rem * 2);
  }
  .grid-m-9 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-9 > li:nth-of-type(9n + 1) {
    clear: both;
  }
  .grid-m-9 .gallery-cell {
    width: calc(100% / 9);
    padding: 1rem;
  }
  .col-m-10 {
    width: calc(100% / 12 * 10);
    padding: 0 1rem;
  }
  .col-m-offset-10 {
    margin-left: calc(100% / 12 * 10);
  }
  .grid-m-10 > li {
    width: calc(100% / 10 - 1rem * 2);
  }
  .grid-m-10 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-10 > li:nth-of-type(10n + 1) {
    clear: both;
  }
  .grid-m-10 .gallery-cell {
    width: calc(100% / 10);
    padding: 1rem;
  }
  .col-m-11 {
    width: calc(100% / 12 * 11);
    padding: 0 1rem;
  }
  .col-m-offset-11 {
    margin-left: calc(100% / 12 * 11);
  }
  .grid-m-11 > li {
    width: calc(100% / 11 - 1rem * 2);
  }
  .grid-m-11 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-11 > li:nth-of-type(11n + 1) {
    clear: both;
  }
  .grid-m-11 .gallery-cell {
    width: calc(100% / 11);
    padding: 1rem;
  }
  .col-m-12 {
    width: calc(100% / 12 * 12);
    padding: 0 1rem;
  }
  .col-m-offset-12 {
    margin-left: calc(100% / 12 * 12);
  }
  .grid-m-12 > li {
    width: calc(100% / 12 - 1rem * 2);
  }
  .grid-m-12 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-m-12 > li:nth-of-type(12n + 1) {
    clear: both;
  }
  .grid-m-12 .gallery-cell {
    width: calc(100% / 12);
    padding: 1rem;
  }
}

@media screen and (min-width: 64em) and (max-width: 70em) {
  .l-hide {
    display: none;
  }
}

@media screen and (min-width: 64em) {
  .col-l-1 {
    width: calc(100% / 12 * 1);
    padding: 0 1rem;
  }
  .col-l-offset-1 {
    margin-left: calc(100% / 12 * 1);
  }
  .grid-l-1 > li {
    width: calc(100% / 1 - 1rem * 2);
  }
  .grid-l-1 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-1 > li:nth-of-type(1n + 1) {
    clear: both;
  }
  .grid-l-1 .gallery-cell {
    width: calc(100% / 1);
    padding: 1rem;
  }
  .col-l-2 {
    width: calc(100% / 12 * 2);
    padding: 0 1rem;
  }
  .col-l-offset-2 {
    margin-left: calc(100% / 12 * 2);
  }
  .grid-l-2 > li {
    width: calc(100% / 2 - 1rem * 2);
  }
  .grid-l-2 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-2 > li:nth-of-type(2n + 1) {
    clear: both;
  }
  .grid-l-2 .gallery-cell {
    width: calc(100% / 2);
    padding: 1rem;
  }
  .col-l-3 {
    width: calc(100% / 12 * 3);
    padding: 0 1rem;
  }
  .col-l-offset-3 {
    margin-left: calc(100% / 12 * 3);
  }
  .grid-l-3 > li {
    width: calc(100% / 3 - 1rem * 2);
  }
  .grid-l-3 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-3 > li:nth-of-type(3n + 1) {
    clear: both;
  }
  .grid-l-3 .gallery-cell {
    width: calc(100% / 3);
    padding: 1rem;
  }
  .col-l-4 {
    width: calc(100% / 12 * 4);
    padding: 0 1rem;
  }
  .col-l-offset-4 {
    margin-left: calc(100% / 12 * 4);
  }
  .grid-l-4 > li {
    width: calc(100% / 4 - 1rem * 2);
  }
  .grid-l-4 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-4 > li:nth-of-type(4n + 1) {
    clear: both;
  }
  .grid-l-4 .gallery-cell {
    width: calc(100% / 4);
    padding: 1rem;
  }
  .col-l-5 {
    width: calc(100% / 12 * 5);
    padding: 0 1rem;
  }
  .col-l-offset-5 {
    margin-left: calc(100% / 12 * 5);
  }
  .grid-l-5 > li {
    width: calc(100% / 5 - 1rem * 2);
  }
  .grid-l-5 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-5 > li:nth-of-type(5n + 1) {
    clear: both;
  }
  .grid-l-5 .gallery-cell {
    width: calc(100% / 5);
    padding: 1rem;
  }
  .col-l-6 {
    width: calc(100% / 12 * 6);
    padding: 0 1rem;
  }
  .col-l-offset-6 {
    margin-left: calc(100% / 12 * 6);
  }
  .grid-l-6 > li {
    width: calc(100% / 6 - 1rem * 2);
  }
  .grid-l-6 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-6 > li:nth-of-type(6n + 1) {
    clear: both;
  }
  .grid-l-6 .gallery-cell {
    width: calc(100% / 6);
    padding: 1rem;
  }
  .col-l-7 {
    width: calc(100% / 12 * 7);
    padding: 0 1rem;
  }
  .col-l-offset-7 {
    margin-left: calc(100% / 12 * 7);
  }
  .grid-l-7 > li {
    width: calc(100% / 7 - 1rem * 2);
  }
  .grid-l-7 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-7 > li:nth-of-type(7n + 1) {
    clear: both;
  }
  .grid-l-7 .gallery-cell {
    width: calc(100% / 7);
    padding: 1rem;
  }
  .col-l-8 {
    width: calc(100% / 12 * 8);
    padding: 0 1rem;
  }
  .col-l-offset-8 {
    margin-left: calc(100% / 12 * 8);
  }
  .grid-l-8 > li {
    width: calc(100% / 8 - 1rem * 2);
  }
  .grid-l-8 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-8 > li:nth-of-type(8n + 1) {
    clear: both;
  }
  .grid-l-8 .gallery-cell {
    width: calc(100% / 8);
    padding: 1rem;
  }
  .col-l-9 {
    width: calc(100% / 12 * 9);
    padding: 0 1rem;
  }
  .col-l-offset-9 {
    margin-left: calc(100% / 12 * 9);
  }
  .grid-l-9 > li {
    width: calc(100% / 9 - 1rem * 2);
  }
  .grid-l-9 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-9 > li:nth-of-type(9n + 1) {
    clear: both;
  }
  .grid-l-9 .gallery-cell {
    width: calc(100% / 9);
    padding: 1rem;
  }
  .col-l-10 {
    width: calc(100% / 12 * 10);
    padding: 0 1rem;
  }
  .col-l-offset-10 {
    margin-left: calc(100% / 12 * 10);
  }
  .grid-l-10 > li {
    width: calc(100% / 10 - 1rem * 2);
  }
  .grid-l-10 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-10 > li:nth-of-type(10n + 1) {
    clear: both;
  }
  .grid-l-10 .gallery-cell {
    width: calc(100% / 10);
    padding: 1rem;
  }
  .col-l-11 {
    width: calc(100% / 12 * 11);
    padding: 0 1rem;
  }
  .col-l-offset-11 {
    margin-left: calc(100% / 12 * 11);
  }
  .grid-l-11 > li {
    width: calc(100% / 11 - 1rem * 2);
  }
  .grid-l-11 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-11 > li:nth-of-type(11n + 1) {
    clear: both;
  }
  .grid-l-11 .gallery-cell {
    width: calc(100% / 11);
    padding: 1rem;
  }
  .col-l-12 {
    width: calc(100% / 12 * 12);
    padding: 0 1rem;
  }
  .col-l-offset-12 {
    margin-left: calc(100% / 12 * 12);
  }
  .grid-l-12 > li {
    width: calc(100% / 12 - 1rem * 2);
  }
  .grid-l-12 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-l-12 > li:nth-of-type(12n + 1) {
    clear: both;
  }
  .grid-l-12 .gallery-cell {
    width: calc(100% / 12);
    padding: 1rem;
  }
}

@media screen and (min-width: 70em) {
  .col-xl-1 {
    width: calc(100% / 12 * 1);
    padding: 0 1rem;
  }
  .col-xl-offset-1 {
    margin-left: calc(100% / 12 * 1);
  }
  .grid-xl-1 > li {
    width: calc(100% / 1 - 1rem * 2);
  }
  .grid-xl-1 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-1 > li:nth-of-type(1n + 1) {
    clear: both;
  }
  .grid-xl-1 .gallery-cell {
    width: calc(100% / 1);
    padding: 1rem;
  }
  .col-xl-2 {
    width: calc(100% / 12 * 2);
    padding: 0 1rem;
  }
  .col-xl-offset-2 {
    margin-left: calc(100% / 12 * 2);
  }
  .grid-xl-2 > li {
    width: calc(100% / 2 - 1rem * 2);
  }
  .grid-xl-2 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-2 > li:nth-of-type(2n + 1) {
    clear: both;
  }
  .grid-xl-2 .gallery-cell {
    width: calc(100% / 2);
    padding: 1rem;
  }
  .col-xl-3 {
    width: calc(100% / 12 * 3);
    padding: 0 1rem;
  }
  .col-xl-offset-3 {
    margin-left: calc(100% / 12 * 3);
  }
  .grid-xl-3 > li {
    width: calc(100% / 3 - 1rem * 2);
  }
  .grid-xl-3 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-3 > li:nth-of-type(3n + 1) {
    clear: both;
  }
  .grid-xl-3 .gallery-cell {
    width: calc(100% / 3);
    padding: 1rem;
  }
  .col-xl-4 {
    width: calc(100% / 12 * 4);
    padding: 0 1rem;
  }
  .col-xl-offset-4 {
    margin-left: calc(100% / 12 * 4);
  }
  .grid-xl-4 > li {
    width: calc(100% / 4 - 1rem * 2);
  }
  .grid-xl-4 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-4 > li:nth-of-type(4n + 1) {
    clear: both;
  }
  .grid-xl-4 .gallery-cell {
    width: calc(100% / 4);
    padding: 1rem;
  }
  .col-xl-5 {
    width: calc(100% / 12 * 5);
    padding: 0 1rem;
  }
  .col-xl-offset-5 {
    margin-left: calc(100% / 12 * 5);
  }
  .grid-xl-5 > li {
    width: calc(100% / 5 - 1rem * 2);
  }
  .grid-xl-5 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-5 > li:nth-of-type(5n + 1) {
    clear: both;
  }
  .grid-xl-5 .gallery-cell {
    width: calc(100% / 5);
    padding: 1rem;
  }
  .col-xl-6 {
    width: calc(100% / 12 * 6);
    padding: 0 1rem;
  }
  .col-xl-offset-6 {
    margin-left: calc(100% / 12 * 6);
  }
  .grid-xl-6 > li {
    width: calc(100% / 6 - 1rem * 2);
  }
  .grid-xl-6 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-6 > li:nth-of-type(6n + 1) {
    clear: both;
  }
  .grid-xl-6 .gallery-cell {
    width: calc(100% / 6);
    padding: 1rem;
  }
  .col-xl-7 {
    width: calc(100% / 12 * 7);
    padding: 0 1rem;
  }
  .col-xl-offset-7 {
    margin-left: calc(100% / 12 * 7);
  }
  .grid-xl-7 > li {
    width: calc(100% / 7 - 1rem * 2);
  }
  .grid-xl-7 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-7 > li:nth-of-type(7n + 1) {
    clear: both;
  }
  .grid-xl-7 .gallery-cell {
    width: calc(100% / 7);
    padding: 1rem;
  }
  .col-xl-8 {
    width: calc(100% / 12 * 8);
    padding: 0 1rem;
  }
  .col-xl-offset-8 {
    margin-left: calc(100% / 12 * 8);
  }
  .grid-xl-8 > li {
    width: calc(100% / 8 - 1rem * 2);
  }
  .grid-xl-8 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-8 > li:nth-of-type(8n + 1) {
    clear: both;
  }
  .grid-xl-8 .gallery-cell {
    width: calc(100% / 8);
    padding: 1rem;
  }
  .col-xl-9 {
    width: calc(100% / 12 * 9);
    padding: 0 1rem;
  }
  .col-xl-offset-9 {
    margin-left: calc(100% / 12 * 9);
  }
  .grid-xl-9 > li {
    width: calc(100% / 9 - 1rem * 2);
  }
  .grid-xl-9 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-9 > li:nth-of-type(9n + 1) {
    clear: both;
  }
  .grid-xl-9 .gallery-cell {
    width: calc(100% / 9);
    padding: 1rem;
  }
  .col-xl-10 {
    width: calc(100% / 12 * 10);
    padding: 0 1rem;
  }
  .col-xl-offset-10 {
    margin-left: calc(100% / 12 * 10);
  }
  .grid-xl-10 > li {
    width: calc(100% / 10 - 1rem * 2);
  }
  .grid-xl-10 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-10 > li:nth-of-type(10n + 1) {
    clear: both;
  }
  .grid-xl-10 .gallery-cell {
    width: calc(100% / 10);
    padding: 1rem;
  }
  .col-xl-11 {
    width: calc(100% / 12 * 11);
    padding: 0 1rem;
  }
  .col-xl-offset-11 {
    margin-left: calc(100% / 12 * 11);
  }
  .grid-xl-11 > li {
    width: calc(100% / 11 - 1rem * 2);
  }
  .grid-xl-11 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-11 > li:nth-of-type(11n + 1) {
    clear: both;
  }
  .grid-xl-11 .gallery-cell {
    width: calc(100% / 11);
    padding: 1rem;
  }
  .col-xl-12 {
    width: calc(100% / 12 * 12);
    padding: 0 1rem;
  }
  .col-xl-offset-12 {
    margin-left: calc(100% / 12 * 12);
  }
  .grid-xl-12 > li {
    width: calc(100% / 12 - 1rem * 2);
  }
  .grid-xl-12 > li:nth-of-type(1n) {
    clear: none;
  }
  .grid-xl-12 > li:nth-of-type(12n + 1) {
    clear: both;
  }
  .grid-xl-12 .gallery-cell {
    width: calc(100% / 12);
    padding: 1rem;
  }
}

header {
  width: 100%;
  display: block;
  position: fixed;
  z-index: 99;
  -webkit-transition: -webkit-transform .4s ease;
  -ms-transition: -ms-transform .4s ease;
  transition: transform .4s ease;
}

.hide-alert header {
  -webkit-transform: translate(0, -3rem);
  -ms-transform: translate(0, -3rem);
  transform: translate(0, -3rem);
}

.alert {
  display: block;
  height: 3rem;
  background-color: pink;
  text-align: center;
  line-height: 1rem;
  padding: 1rem;
}

.alert--close {
  float: right;
  margin-top: -.25rem;
}

.navigation {
  height: 5rem;
  padding: 1rem;
  text-align: center;
  position: relative;
}

.navigation--link-base {
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

.navigation img {
  height: 3rem;
  float: left;
}

.navigation--link-cart {
  top: 1rem;
  right: 1rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
  display: inline-block;
  border-radius: 50%;
  background-color: black;
  color: white;
  text-align: center;
  line-height: 3rem;
  cursor: pointer;
  text-decoration: none;
  transition: background-color .25s ease;
}

.navigation--link-cart:hover {
  background-color: pink;
}

main {
  padding-top: 5rem;
}

.cart {
  width: 90%;
  margin: 0 auto;
}

@media screen and (min-width: 37.5em) {
  .cart {
    width: 66%;
  }
}

@media screen and (min-width: 64em) {
  .cart {
    width: 50%;
  }
}

.cart--overview {
  border: 3px solid black;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  box-sizing: content-box;
  line-height: 2rem;
}

@media screen and (min-width: 37.5em) {
  .cart--overview {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}

.cart--overview ul {
  display: table;
  width: 100%;
}

.cart--overview ul > li {
  display: table-row;
}

.cart--item > span {
  display: table-cell;
}

.cart--item-quantity {
  width: 6rem;
  padding-right: 0.5rem;
  margin-bottom: 3px;
}

.cart--item-name {
  padding-right: 2rem;
}

.cart--item-option {
  padding-right: 1rem;
}

.cart--item-price-single, .cart--item-price-total {
  padding-left: 1rem;
  text-align: right;
}

.cart--item-price-single {
  display: none !important;
}

.cart--footer {
  border-top: 3px solid black;
  margin-top: 1rem;
  padding-top: .5rem;
}

.cart--footer-shipping span {
  float: right;
}

.cart--footer-total span {
  float: right;
}

.cart--hint {
  color: grey;
  display: none;
}

@media screen and (min-width: 37.5em) {
  .cart--hint {
    display: block;
    margin: 1rem 0;
  }
}

.cart--button-proceed {
  padding: 1rem;
  float: left;
}

.cart--button-submit {
  float: right;
}

.cart--no-items {
  text-align: center;
  margin-top: 3rem;
}

.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: .5rem;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
}

.product--order {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

.product--order input[type='radio'] {
  background-color: white;
  border: 3px solid black;
  width: 1rem;
  height: 1rem;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
}

.product--order input[type='radio']:focus {
  box-shadow: none;
}

.product--order input[type='radio']:checked {
  background-color: black;
}

.product--order input[type='radio']:disabled {
  border-color: rgba(0, 0, 0, 0.05);
  cursor: auto;
}

.product--order input[type='radio']:disabled + label {
  color: rgba(0, 0, 0, 0.05);
  cursor: auto;
}

.product--order label {
  cursor: pointer;
}

.product--order button {
  margin-top: 1rem;
}

.product--image {
  width: 100%;
}

@media screen and (min-width: 37.5em) {
  .product--image {
    width: 50%;
    float: left;
  }
}

.product--image img {
  width: 100%;
}

.product--info {
  margin: 2rem;
  text-align: center;
}

@media screen and (min-width: 37.5em) {
  .product--info {
    width: calc(50% - 4rem);
    float: left;
  }
}

.product--info-description {
  margin-bottom: 1rem;
}

.product--info-price {
  margin-bottom: .5rem;
  text-transform: uppercase;
}

.success {
  width: 50%;
  margin: 0 auto;
}

/* Custom Normalize */
button,
select,
input {
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  border-radius: 0;
  line-height: inherit;
}

input,
select {
  border-bottom: 2px solid black;
  padding: .5rem 0;
  width: 100%;
  background-color: transparent;
}

input:focus,
select:focus {
  border-bottom-color: pink;
}

label {
  display: block;
  font-size: small;
}

.badge {
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: block;
  text-align: center;
}

.badge-success {
  background-color: green;
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
}

.form-group .badge {
  position: absolute;
  bottom: .5rem;
  right: 0;
}

.form-group.has-error {
  color: red;
}

.form-group.has-error input {
  border-bottom-color: red;
  color: black;
}

button {
  cursor: pointer;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

*:focus {
  outline: none;
}

*:hover {
  box-shadow: none;
}

button,
a {
  transition: background-color .25s ease;
}

::-moz-selection {
  background-color: pink;
  color: white;
}

::selection {
  background-color: pink;
  color: white;
}

/* Design */
[class*='button-'] {
  display: inline-block;
  padding: 1rem;
  text-decoration: none;
}

.button-black {
  background-color: black;
  color: white;
}

.button-pink {
  background-color: pink;
  color: white;
}

.button-pink:hover {
  background-color: black;
}

.button-close {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  padding: 0;
}

.label {
  padding: .5rem 1rem;
  display: inline-block;
  margin: 0 auto;
  position: relative;
}

.label:after {
  content: '';
  display: block;
  border: 1rem solid transparent;
  border-left-color: pink;
  position: absolute;
  bottom: -.9rem;
  left: 0;
}

.label-warning {
  background-color: pink;
}

p {
  margin-bottom: 1rem;
}

.box-border {
  border: 3px solid black;
  padding: 1rem 2rem;
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: 1rem;
}

ol {
  list-style-type: decimal;
  margin-bottom: 1rem;
}

ul {
  margin-bottom: 1rem;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.input-number {
  background-color: black;
  position: relative;
  padding: 0 2rem;
}

.input-number form {
  display: inline-block;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
}

.input-number form:first-of-type {
  left: 0;
}

.input-number form:last-of-type {
  right: 0;
}

.input-number div {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
}

.input-number button {
  color: white;
  display: inline-block;
  width: 100%;
  line-height: 2rem;
  text-align: center;
  border-radius: 50%;
}

.input-number button:focus {
  box-shadow: none;
}

#image-02 {
  position: relative;
  left: -6rem;
  margin-top: 2rem;
}

#image-03 {
  width: 80%;
  margin: 1rem 5% 2rem 15%;
}

.fragen {
  width: 90%;
  margin: 0 auto;
}

.fragen p {
  margin-bottom: 1rem;
}

@media screen and (min-width: 37.5em) {
  .fragen {
    width: 50%;
  }
}

.loader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility .0s .3s, opacity 0.25s ease;
}

.is-added:before {
  content: '';
  display: block;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: addition 0.5s ease-out;
  -moz-animation: addition 0.5s ease-out;
  -o-animation: addition 0.5s ease-out;
  animation: addition 0.5s ease-out;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: pink;
}

.is-added:focus {
  box-shadow: none;
}

.is-loader .loader {
  visibility: visible;
  opacity: 1;
  transition: visibility .0s, opacity 0.25s ease;
}

.is-loader .navigation--link-base {
  -webkit-animation: rotate-infinite 1s linear infinite;
  -moz-animation: rotate-infinite 1s linear infinite;
  animation: rotate-infinite 1s linear infinite;
}

@-webkit-keyframes rotate-infinite {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-infinite {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate-infinite {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes addition {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

@-moz-keyframes addition {
  0% {
    -moz-transform: scale(1);
    opacity: 1;
  }
  100% {
    -moz-transform: scale(2);
    opacity: 0;
  }
}

@keyframes addition {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.debug {
  background-color: #111;
  color: white;
  font-size: small;
  padding: 3rem 1rem;
  margin: 1rem;
  position: relative;
}

.debug:before {
  content: 'DEBUG INFORMATION';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: pink;
  color: black;
  width: 100%;
  padding: .5rem 1rem;
  box-sizing: border-box;
}

footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@font-face {
  font-family: 'Moderat';
  src: url("../fonts/moderat-regular-webfont.eot");
  src: url("../fonts/moderat-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/moderat-regular-webfont.woff2") format("woff2"), url("../fonts/moderat-regular-webfont.woff") format("woff"), url("../fonts/moderat-regular-webfont.ttf") format("truetype"), url("../fonts/moderat-regular-webfont.svg#moderatregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Moderat';
  src: url("../fonts/moderat-bold-webfont.eot");
  src: url("../fonts/moderat-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/moderat-bold-webfont.woff2") format("woff2"), url("../fonts/moderat-bold-webfont.woff") format("woff"), url("../fonts/moderat-bold-webfont.ttf") format("truetype"), url("../fonts/moderat-bold-webfont.svg#moderatbold") format("svg");
  font-weight: bold;
  font-style: normal;
}
