/**
 *
 * Defining basic typography rules
 *
**/


html {
  font-size: 70%;
}

@media screen and (min-width: 481px) {
  html {
    font-size: 90%;
  }
}

@media screen and (min-width: 769px) {
  html {
    font-size: 100%;
  }
}

@media screen and (min-width: 961px) {
  html {
    font-size: 110%;
  }
}


body {
  font-family: 'Moderat', 'Arial', sans-serif;
  line-height: 1.5;
  letter-spacing: -0.015em;
}



h1 {
  font-size: 4rem;
}

h2 {
  border-bottom: 3px solid black;
  display: inline-block;
  margin-bottom: 1rem;
}

h4 {
  display: inline;
  background-color: pink;
}

h5 {

}

h6 {

}


a {
  color: pink;
}

strong {
  font-weight: bold;
}

small {
  font-size: 0.75em;
}


.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
