
header {
  width: 100%;
  display: block;
  position: fixed;
  z-index: 99;
  -webkit-transition: -webkit-transform .4s ease;
  -ms-transition: -ms-transform .4s ease;
  transition: transform .4s ease;
}

.hide-alert {
  header {
    @include translate(0, -3rem);
  }
}

.alert {
  display: block;
  height: 3rem;
  background-color: pink;
  text-align: center;
  line-height: 1rem;
  padding: 1rem;

  &--close {
    float: right;
    margin-top: -.25rem;
  }
}

.navigation {
  height: 5rem;
  padding: 1rem;
  text-align: center;
  position: relative;

  &--link-base {
    display: inline-block;
    border-radius: 50%;
    position: relative;
  }

  img {
    height: 3rem;
    float: left;
  }

  &--link-cart {
    top: 1rem;
    right: 1rem;
    position: absolute;
    width: 3rem;
    height: 3rem;
    display: inline-block;
    border-radius: 50%;
    background-color: black;
    color: white;
    text-align: center;
    line-height: 3rem;
    cursor: pointer;
    text-decoration: none;
    transition: background-color .25s ease;

    &:hover {
      background-color: pink;
    }
  }
}


main {
  padding-top: 5rem;

  //&:before {
  //  content: '';
  //  display: block;
  //  height: 100%;
  //  width: 100%;
  //  background-color: white;
  //  opacity: 0;
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  z-index: 98;
  //  transition: opacity .4s ease;
  //  pointer-events: none;
  //}
}