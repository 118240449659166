@import 'variables';

@mixin breakpoint($point) {
  @if $point == s {
    @content;
  }
  @else if $point == m {
    @media screen and (min-width: $point-m-min) {
      @content;
    }
  }
  @else if $point == l {
    @media screen and (min-width: $point-l-min) {
      @content;
    }
  }
  @else if $point == xl {
    @media screen and (min-width: $point-xl-min) {
      @content;
    }
  }
}

@mixin twixt($point) {
  @if $point == s {
    @media screen and (max-width: $point-s-max) {
      @content;
    }
  }
  @else if $point == m {
    @media screen and (min-width: $point-m-min) and (max-width: $point-m-max) {
      @content;
    }
  }
  @else if $point == l {
    @media screen and (min-width: $point-l-min) and (max-width: $point-l-max) {
      @content;
    }
  }
}




@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
  @include transform(scale($scale));
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -o-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin transition($transforms) {
  -webkit-transition: $transforms;
  -ms-transition: $transforms;
  transition: $transforms;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content
  }
;
  @-moz-keyframes #{$name} {
    @content
  }
;
  @-o-keyframes #{$name} {
    @content
  }
;
  @-keyframes #{$name} {
    @content
  }
;
}

@mixin animation($values) {
  -webkit-animation: $values;
  -moz-animation: $values;
  -o-animation: $values;
  animation: $values;
}

